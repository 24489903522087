import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signupParentStep2, setCurrentUser } from '../../actions/action_auth';
import { getStudents, getParentData } from '../../actions/action_parent';

// React.js components
import Dropdown from '../utils/dropdown';

class ParentSignupFormStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      errors: {},
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderStateDropdown() {
    const states = [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ];

    return (
      <Dropdown
        className="signup-form__input border--round"
        handleOnChange={this.handleOnChange}
        name="state"
        options={states}
        value={this.state.state}
      />
    );
  }

  handleOnSubmit(event) {
    event.preventDefault();
    const data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone: this.state.phone,
      address: {
        address_1: this.state.address1,
        address_2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zipcode: this.state.zipcode,
      },
    };

    this.setState({ errors: {} }, () => {
      this.props.signupParentStep2(data).then((response) => {
        if (response.payload.data.errors) {
          this.setState({ errors: response.payload.data.errors });
        } else {
          this.props.getParentData();
          this.props.setCurrentUser(this.props.auth.authToken);
          this.props.history.push('/dashboard');
        }
      });
    });
  }

  getBgClass() {
    return 'bg--bnl-light-blue';
  }

  render() {
    const errors = this.state.errors;
    const firstNameErrorClassName = errors['firstName']
      ? 'error--red'
      : 'hidden';
    const lastNameErrorClassName = errors['lastName'] ? 'error--red' : 'hidden';
    const phoneErrorClassName = errors['phone'] ? 'error--red' : 'hidden';
    const address1ErrorClassName = errors['address1'] ? 'error--red' : 'hidden';
    const cityErrorClassName = errors['city'] ? 'error--red' : 'hidden';
    const stateErrorClassName = errors['state'] ? 'error--red' : 'hidden';
    const zipcodeErrorClassName = errors['zipcode'] ? 'error--red' : 'hidden';
    const bgClass = this.getBgClass();

    return (
      <div>
        <div className="signup__header">Create Your Account</div>
        <div className="signup__subheader">
          Please enter parent details. We value your privacy and won’t sell or
          repurpose your data in any way.
        </div>
        <form onSubmit={this.handleOnSubmit}>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="firstName"
            value={this.state.firstName}
            placeholder="First Name"
          />
          <div className={`signup-error ${firstNameErrorClassName}`}>
            {errors['firstName']}
          </div>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="lastName"
            value={this.state.lastName}
            placeholder="Last Name"
          />
          <div className={`signup-error ${lastNameErrorClassName}`}>
            {errors['lastName']}
          </div>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="phone"
            value={this.state.phone}
            placeholder="Mobile Phone Number"
          />
          <div className={`signup-error ${phoneErrorClassName}`}>
            {errors['phone']}
          </div>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="address1"
            value={this.state.address1}
            placeholder="Address 1"
          />
          <div className={`signup-error ${address1ErrorClassName}`}>
            {errors['address1']}
          </div>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="address2"
            value={this.state.address2}
            placeholder="Address 2"
          />
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="city"
            value={this.state.city}
            placeholder="City"
          />
          <div className={`signup-error ${cityErrorClassName}`}>
            {errors['city']}
          </div>
          {this.renderStateDropdown()}
          <div className={`signup-error ${stateErrorClassName}`}>
            {errors['state']}
          </div>
          <input
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="zipcode"
            value={this.state.zipcode}
            placeholder="Zipcode"
          />
          <div className={`signup-error ${zipcodeErrorClassName}`}>
            {errors['zipcode']}
          </div>
          <input
            className={`display--block button--round-border ${bgClass}`}
            value="Done"
            type="submit"
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { signupParentStep2, setCurrentUser, getStudents, getParentData },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentSignupFormStep2);
