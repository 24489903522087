import _ from 'lodash';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { setSmsPhone } from './action_parent';

import { mapCartTotalsResponseToCamelCase } from '../utils/api';
import cartUtils from '../utils/cart';
import cart from '../utils/cart';

export const V3_POP_ITEM_FROM_CONFIG = 'V3_POP_ITEM_FROM_CONFIG';
export const V3_SET_PRODUCT_CONFIG = 'V3_SET_PRODUCT_CONFIG';
export const V3_REMOVE_PRODUCT_CONFIG = 'V3_REMOVE_PRODUCT_CONFIG';
export const V3_REMOVE_LAST_PRODUCT_CONFIG = 'V3_REMOVE_LAST_PRODUCT_CONFIG';
export const V3_CLEAR_PRODUCT_CONFIG = 'V3_CLEAR_PRODUCT_CONFIG';
export const V3_ADD_TO_CART = 'V3_ADD_TO_CART';
export const V3_MARK_RETOUCHING_PROMPTED = 'V3_MARK_RETOUCHING_PROMPTED';
export const V3_DECLINE_INTERSTITIAL_PRODUCT =
  'V3_DECLINE_INTERSTITIAL_PRODUCT';
export const V3_DECLINE_PRE_CART_OFFER_PRODUCT =
  'V3_DECLINE_PRE_CART_OFFER_PRODUCT';
export const V3_DECLINE_RETOUCHING = 'V3_DECLINE_RETOUCHING';
export const V3_DECLINE_PERSONALIZATION = 'V3_DECLINE_PERSONALIZATION';
export const V3_SUBMIT_PERSONALIZATION = 'V3_SUBMIT_PERSONALIZATION';
export const V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS =
  'V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS';
export const V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS =
  'V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS';
export const V3_STORE_CART_SUCCESS = 'V3_STORE_CART_SUCCESS';
export const V3_STORE_CART_ERROR = 'V3_STORE_CART_ERROR';
export const V3_STORE_CART = 'V3_STORE_CART';
export const V3_SUBMITTING_CART = 'V3_SUBMITTING_CART';
export const V3_SUBMIT_CART_SUCCESS = 'SUCCESS';
export const V3_SUBMIT_CART_ERROR = 'V3_SUBMIT_CART_ERROR';
export const V3_CLEAR_CART = 'V3_CLEAR_CART';
export const V3_REMOVE_FROM_CONFIG_STACK = 'V3_REMOVE_FROM_CONFIG_STACK';
export const V3_ADD_TO_CONFIG_STACK = 'V3_ADD_TO_CONFIG_STACK';
export const V3_REMOVE_INDEX_FROM_PRODUCT_CONFIG =
  'V3_REMOVE_INDEX_FROM_PRODUCT_CONFIG';
export const V3_REMOVE_CART_ITEM = 'V3_REMOVE_CART_ITEM';
export const V3_RESET_RETOUCHING = 'V3_RESET_RETOUCHING';
export const V3_UPDATE_PARENT_SMS_NUMBER = 'V3_UPDATE_PARENT_SMS_NUMBER';
export const V3_UPDATE_SMS_NUMBER_CONFIRMED = 'V3_UPDATE_SMS_NUMBER_CONFIRMED';
export const V3_REMOVE_ITEM_FROM_CONFIG = 'V3_REMOVE_ITEM_FROM_CONFIG';
export const V3_APPLY_DISCOUNT_CODE_TO_CART = 'V3_APPLY_DISCOUNT_CODE_TO_CART';
export const V3_CHECKOUT_LOADING = 'V3_CHECKOUT_LOADING';
export const V3_RESET_SUBMITTING_ORDER = 'V3_RESET_SUBMITTING_ORDER';
export const V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED =
  'V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED';
export const V3_CONFIRM_SHIPPING_ADDRESS = 'V3_CONFIRM_SHIPPING_ADDRESS';

export const setProductConfig =
  ({ index, itemToAdd }) =>
  (dispatch, getState) => {
    const {
      isPrepay,
      postpayRequiresSameValuePersonalization,
      prepayRequiresSameValuePersonalization,
    } = getState().shoot;

    dispatch({
      type: V3_SET_PRODUCT_CONFIG,
      payload: {
        index,
        isPrepay,
        itemToAdd,
        prepayRequiresSameValuePersonalization,
      },
    });
  };

export function popItemFromConfig(index, section) {
  return {
    type: V3_POP_ITEM_FROM_CONFIG,
    payload: { index, section },
  };
}

export function removeItemFromConfig(index, history) {
  return {
    type: V3_REMOVE_ITEM_FROM_CONFIG,
    payload: { index, history },
  };
}

export function removeLastProductConfig({ index, section }) {
  return {
    type: V3_REMOVE_LAST_PRODUCT_CONFIG,
    payload: { index, section },
  };
}

export function clearProductConfig(key) {
  return {
    type: V3_CLEAR_PRODUCT_CONFIG,
    payload: key,
  };
}

export function removeIndexFromProductConfig({ toRemove, index, history }) {
  return {
    type: V3_REMOVE_INDEX_FROM_PRODUCT_CONFIG,
    payload: { toRemove, index, history },
  };
}

export function removeFromConfigStack(number) {
  return {
    type: V3_REMOVE_FROM_CONFIG_STACK,
    payload: number,
  };
}

export function addToConfigStack(paths) {
  return {
    type: V3_ADD_TO_CONFIG_STACK,
    payload: paths,
  };
}

export const addToCart =
  ({
    studentId,
    fullproductData,
    backgroundIds,
    backgroundSelections,
    poseIds,
    poses,
    isInterstitialProduct = false,
    quantity = 1,
    personalizationValue = null,
    redirectSource = null,
    requiredFields,
    productOptionKey,
  }) =>
  async (dispatch, getState) => {
    const {
      incentiveProducts,
      isPrepay,
      personalizationProduct,
      postpayRequiresSameValuePersonalization,
      prepayRequiresSameValuePersonalization,
      shoot,
    } = getState().shoot;

    console.log('----------------------------------------');
    console.log('----------------------------------------');
    console.log('>> ADD TO CART - V3');
    console.log(personalizationProduct);
    console.log(backgroundIds);
    console.log('----------------------------------------');
    console.log('----------------------------------------');

    const additionalData = getState().additionalData;
    const { orders, productConfig } = getState().v3Order;
    const { selectedStudent } = getState().additionalData;
    const incentiveProductIds = incentiveProducts.map((product) => product.id);

    const currentOrder = orders[selectedStudent?.id];
    const cartItems = currentOrder?.cartItems || [];

    const cartItemsIncludeRetouching =
      cartUtils.cartItemsIncludeRetouching(cartItems);
    const cartItemsContainRetouchingProduct =
      cartUtils.cartItemsContainRetouchingProduct(cartItems);

    const formattedRequiredFields = [];
    const requiredProductFields = fullproductData.required_product_fields;

    if (Array.isArray(requiredProductFields)) {
      requiredProductFields.forEach((field) => {
        const productField = {
          id: field.id,
          key: field.key,
          name: field.name,
          label: field.label,
          value: requiredFields[field.name],
        };
        formattedRequiredFields.push(productField);
      });
    }

    // When adding the retouching product, ensure it's not in the cart first
    if (fullproductData.is_retouching) {
      if (cartItemsIncludeRetouching || cartItemsContainRetouchingProduct) {
        const message = cartItemsIncludeRetouching
          ? 'One of the items in the cart already includes retouching :)'
          : 'You already have retouching added to your order :)';

        return Swal.fire({
          title: 'Retouching already added',
          text: message,
          icon: 'info',
          // showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
      }
    }

    // TODO: don't allow users to add personalization more than once
    // TODO: don't allow users to add retouching to an empty cart
    // TODO: don't allow users to add personalization to an empty cart

    // TODO: describe what this does
    const personalizationSetToSameValue =
      (isPrepay && prepayRequiresSameValuePersonalization) ||
      (!isPrepay && postpayRequiresSameValuePersonalization);

    // TODO: extract this to a utility function (same as above)
    // Specify the keys we want to store in the cart
    const productData = _.pick(fullproductData, [
      'additional_data',
      'background_options_count',
      'contents',
      'id',
      'image_thumbnail',
      'image_type',
      'incentive_threshold',
      'includes_retouching',
      'is_byo',
      'is_retouching',
      'is_personalization',
      'is_ypx',
      'is_multi_config',
      'lock_group',
      'lock_type',
      'personalization_status',
      'poses_count',
      'price',
      'product_type',
      'name',
      'nth_free_amount_allowed',
      'nth_free_product_id',
      'nth_free_sku',
      'nth_free_threshold',
      'nth_free_value',
      'is_pre_cart_offer',
      'requires_shipping',
      'sku',
      'child_products',
      'required_product_fields',
      'product_options',
    ]);

    const isPreCartOfferProduct = productData.is_pre_cart_offer;

    let personalizationValueForCart = null;

    // First look through productConfig, which is what is submitted for YPX and BYO products
    // This case handles orders where the personalization value is set to the same value for all products
    // TODO: handle cases when YPX/BYO products can have different personalization values
    if (
      personalizationSetToSameValue &&
      productConfig &&
      productConfig.length > 0
    ) {
      let personalizationFromProductConfig = null;

      productConfig.forEach((config) => {
        if (config.personalization) {
          personalizationFromProductConfig = config.personalization;
        }
      });

      if (
        personalizationFromProductConfig !== null &&
        personalizationFromProductConfig !== ''
      ) {
        personalizationValueForCart = personalizationFromProductConfig;
      }
    }

    // Look at personalizationValue, which is set on the submitted item
    if (
      personalizationValue &&
      personalizationValue !== '' &&
      personalizationValue !== null
    ) {
      personalizationValueForCart = personalizationValue;
    }

    const cartObject = cartUtils.configureCartItem({
      backgroundIds,
      backgroundSelections,
      incentiveProductIds,
      productConfig,
      productData,
      poses,
      poseIds,
      personalizationValueForCart,
      personalizationSetToSameValue,
      quantity,
      requiredFields: formattedRequiredFields,
      productOptionKey,
    });

    // TODO: revisit this logic -- it's a bit confusing to
    // call 'decline' when the product is being added to the cart...
    // we should probably create an action to support this case,
    // something like V3_MARK_INTERSTITIAL_PRODUCT_AS_PROMPTED, etc.
    if (isInterstitialProduct) {
      dispatch({
        type: V3_DECLINE_INTERSTITIAL_PRODUCT,
        payload: { studentId, productId: cartObject.productId },
      });
    }

    let payload = {
      additionalData,
      cartObject,
      isPrepay,
      personalizationSetToSameValue,
      selectedStudent,
      shoot,
      redirectSource,
      requiredFields: formattedRequiredFields,
    };

    const cartObjectChildPersonalizationValues = (cartObject.children || [])
      .map((child) => child.personalizationValue)
      .filter((value) => value !== undefined && value !== null && value !== '');

    // If the cart object or its child products are personalized, send the personalization product (if it exists)
    if (
      personalizationProduct &&
      (cartObject.personalizationValue ||
        cartObjectChildPersonalizationValues.length > 0)
    ) {
      // TODO: extract this to a utility function (same as above)
      const personalizationProductData = _.pick(personalizationProduct, [
        'additional_data',
        'background_options_count',
        'contents',
        'id',
        'image_thumbnail',
        'image_type',
        'incentive_threshold',
        'includes_retouching',
        'is_byo',
        'is_retouching',
        'is_personalization',
        'is_ypx',
        'is_multi_config',
        'lock_group',
        'lock_type',
        'personalization_status',
        'poses_count',
        'price',
        'product_type',
        'name',
        'nth_free_amount_allowed',
        'nth_free_product_id',
        'nth_free_sku',
        'nth_free_threshold',
        'nth_free_value',
        'requires_shipping',
        'sku',
        'child_products',
        'required_product_fields',
        'product_options',
      ]);

      const configuredPersonalizationProduct = cartUtils.configureCartItem({
        backgroundIds: [],
        backgroundSelections: [],
        incentiveProductIds,
        productConfig,
        productData: personalizationProductData,
        poses: [],
        poseIds: [],
        personalizationValueForCart,
        personalizationSetToSameValue,
        quantity,
        requiredFields: formattedRequiredFields,
        // productOption,
      });

      payload = {
        ...payload,
        personalizationProduct: configuredPersonalizationProduct,
      };
    }

    if (isPreCartOfferProduct) {
      dispatch({
        type: V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED,
        payload: { studentId, productId: cartObject.productId },
      });
    }

    dispatch({
      type: V3_ADD_TO_CART,
      payload,
    });
  };

export function declineRetouching(studentId) {
  return {
    type: V3_DECLINE_RETOUCHING,
    payload: { studentId },
  };
}

export function declinePersonalization(studentId) {
  return {
    type: 'V3_DECLINE_PERSONALIZATION',
    payload: { studentId },
  };
}

export function submitPersonalization(studentId, value) {
  return {
    type: 'V3_SUBMIT_PERSONALIZATION',
    payload: { studentId, value },
  };
}

export function setPersonalizationOnAllEligibleCartItems(
  studentId,
  personalizationValue,
  redirectSource = null,
) {
  return {
    type: V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS,
    payload: { personalizationValue, redirectSource, studentId },
  };
}

export function skipPersonalizationOnAllCartItems(
  studentId,
  redirectSource = null,
) {
  return {
    type: V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS,
    payload: { redirectSource, studentId },
  };
}

export function markRetouchingPrompted(studentId) {
  return {
    type: V3_MARK_RETOUCHING_PROMPTED,
    payload: { studentId },
  };
}

export const storeCart = () => async (dispatch, getState) => {
  const { discountCodes, orders, uuid } = getState().v3Order;
  const { studentsDetails } = getState().bnlPreShoot;

  dispatch({ type: V3_STORE_CART });

  // Retrieve the cookie value and attempt to parse it as an integer
  const rawValue = Cookies.get('campaign_event_id');
  const parsedValue = parseInt(rawValue, 10);

  const campaignEventId = isNaN(parsedValue) ? null : parsedValue;

  try {
    const response = await axios.post('/api/v3/orders/store-cart', {
      campaignEventId,
      discountCodes,
      orders,
      studentsDetails,
      uuid,
    });

    if (
      response?.response?.status === 422 &&
      response?.response?.data?.message === 'cart already submitted'
    ) {
      const uuid = response?.response?.data?.uuid;

      Sentry.captureMessage(
        `Cart with UUID ${uuid} was previously submitted and paid for`,
      );
      dispatch({ type: V3_CLEAR_CART });

      return Swal.fire({
        icon: 'warning',
        html: `
          <p>There was an issue detected with your order(s) &mdash; it appears you've previously submitted and paid for your current cart.<p>
          <p>We've cleared your cart contents, please try again.</p>
          <p>When submitting any future payments, please ensure you're redirected to the 'success' screen before closing your browser.<p>
        `,
      }).then((result) => {
        window.location.href = '/v3/offers';
      });
    }

    const cartTotals = mapCartTotalsResponseToCamelCase(response.data);
    dispatch({ type: V3_STORE_CART_SUCCESS, payload: cartTotals });
  } catch (error) {
    console.log(error);

    dispatch({
      type: V3_STORE_CART_ERROR,
      payload: error.message,
    });
  }
};

// TODO: clean this up
export const submitCart = (stripeCancelUrl) => async (dispatch, getState) => {
  const { discountCodes, orders, shippingAddressConfirmed, uuid } =
    getState().v3Order;

  dispatch({ type: V3_SUBMITTING_CART });

  try {
    const response = await axios.post('/api/v3/orders/submit', {
      discountCodes,
      orders,
      shippingAddressConfirmed,
      stripe_cancel_url: stripeCancelUrl,
      uuid,
    });

    // TODO: move the below alerts into a helper function
    if (
      response?.response?.status === 422 &&
      response?.response?.data?.orders_where_prepay_closed?.length > 0
    ) {
      dispatch({
        type: V3_SUBMIT_CART_ERROR,
        payload: response?.response?.data?.message,
      });

      const eventName =
        response?.response?.data?.orders_where_prepay_closed[0]?.event_name;

      return Swal.fire({
        icon: 'warning',
        html: `
          <p>There was an issue with your submission &mdash; prepay orders are closed for <span style="font-style: italic;">${eventName}</span><p>
          <p>Please remove items for this event from your cart and try again.</p>
          <p>You can place an order for <span style="font-style: italic;">${eventName}</span> as soon as your student's image is ready.</p>
        `,
      });
    }

    if (
      response?.response?.status === 422 &&
      response?.response?.data?.error_identifier === 'cart_already_has_order'
    ) {
      dispatch({
        type: V3_SUBMIT_CART_ERROR,
        payload: response?.response?.data?.message,
      });

      return Swal.fire({
        icon: 'warning',
        html: `
          <p>There was an issue with your submission: you've previously placed an order for your current cart.</span><p>
          <p>This situation can occur if your cart wasn't fully cleared after a previous order. Please clear your cart completely and retry your order.</p>
        `,
      });
    }

    if (
      response?.response?.status === 422 &&
      response?.response?.data?.error_identifier === 'missing_incentives'
    ) {
      dispatch({
        type: V3_SUBMIT_CART_ERROR,
        payload: response?.response?.data?.message,
      });

      return Swal.fire({
        icon: 'warning',
        html: `
          <p>There was an issue with your submission: some of the incentive products you qualified for are missing from your cart.</span><p>
          <p>Please clear your cart completely and retry your order. If you run into this issue again, please contact customer support.</p>
        `,
      });
    }

    if (
      response?.response?.status === 422 &&
      response?.response?.data?.error_identifier ===
        'orders_with_multiple_price_lists'
    ) {
      dispatch({
        type: V3_SUBMIT_CART_ERROR,
        payload: response?.response?.data?.message,
      });

      return Swal.fire({
        icon: 'warning',
        html: `
          <p>There was an issue with your submission: one of your student's orders contains items from multiple events.</span><p>
          <p>Please clear your cart and retry your order. Please only place orders for one event per student. If you run into this issue again, please contact customer support.</p>
        `,
      });
    }

    const {
      payment_required,
      message,
      no_payment_required_redirect_url,
      session_url,
    } = response.data;

    if (
      !payment_required &&
      message === 'no payment required' &&
      no_payment_required_redirect_url
    ) {
      return (window.location.href = no_payment_required_redirect_url);
    }

    if (session_url) {
      return (window.location.href = session_url);
    } else {
      // TODO: handle case where session_url is not present. Possibly:
      // - dispatch another error action
      // - display a notification to the user
      // - redirect to another route, etc.
      console.error('session_url is not provided');
    }
  } catch (error) {
    console.log(error);

    dispatch({
      type: V3_SUBMIT_CART_ERROR,
      payload: error.message,
    });
  }
};

export const submitCartSuccess = () => ({
  type: V3_SUBMIT_CART_SUCCESS,
});

export const storeSuccessAndBuildOrder =
  (sessionId, uuid) => async (dispatch, getState) => {
    try {
      const result = await axios.post(
        '/api/v3/orders/store-success-build-order',
        {
          sessionId,
          uuid,
        },
      );

      if (result?.status === 200) {
        // Clear the cart
        dispatch(clearCart());

        // Clear the campaign event ID cookie
        Cookies.remove('campaign_event_id');
      } else {
        // TODO: send error to Sentry
      }
    } catch (error) {
      // TODO: send error to Sentry
    }
  };

export const clearCart = () => ({
  type: V3_CLEAR_CART,
});

export const removeCartItem = (cartItemId, studentId) => {
  return {
    type: V3_REMOVE_CART_ITEM,
    payload: { cartItemId, studentId },
  };
};

export const removeCartItems = (cartItemIds, studentId) => {
  return (dispatch) => {
    cartItemIds.forEach((cartItemId) => {
      dispatch(removeCartItem(cartItemId, studentId));
    });
  };
};

export const resetRetouching = (studentId) => ({
  type: V3_RESET_RETOUCHING,
  payload: { studentId },
});

export const updateSMSNumberConfirmed = (value) => ({
  type: 'V3_UPDATE_SMS_NUMBER_CONFIRMED',
  payload: value,
});

export const updateParentSMSNumber = (id, phoneNumber) => {
  const data = { sms_phone: phoneNumber };

  return async (dispatch) => {
    try {
      const response = await axios.patch(`/api/v2/parents/${id}`, data);
      if (response.status >= 200 && response.status < 300) {
        dispatch({ type: 'V3_UPDATE_SMS_NUMBER_CONFIRMED', payload: true });
        dispatch(setSmsPhone(phoneNumber));
      } else {
        throw new Error('Something went wrong'); // throw an error if the response is not 2xx
      }
    } catch (error) {
      Swal.fire('Something went wrong', error.toString(), 'error'); // show the error to the user
    }
  };
};

export const declineInterstitialProduct = (studentId, productId) => ({
  type: V3_DECLINE_INTERSTITIAL_PRODUCT,
  payload: { studentId, productId },
});

export const declinePreCartOfferProduct = (
  studentId,
  productId,
  redirectSource = null,
) => ({
  type: V3_DECLINE_PRE_CART_OFFER_PRODUCT,
  payload: { productId, redirectSource, studentId },
});

export const checkAndApplyDisountCode =
  (discountCode) => async (dispatch, getState) => {
    dispatch(checkoutIsLoading(true));

    const { pricing_model } = getState()?.shoot?.shoot || {};
    const dataToSubmit = {
      code: discountCode,
      company_pricing_model_id: pricing_model?.id,
    };

    if (!dataToSubmit.code) {
      dispatch(checkoutIsLoading(false));
      return Swal.fire('Error', 'You must submit a code', 'error');
    }

    try {
      const response = await axios.get(
        `/api/v2/discount-codes/apply-discount-code`,
        {
          params: dataToSubmit,
        },
      );

      const { data } = response;

      if (data) {
        if (!data.valid) {
          return Swal.fire('Something went wrong', data.message, 'warning');
        }

        dispatch(applyDiscountCodeToCart(data));
        dispatch(storeCart());
      } else {
        return Swal.fire(
          'Something went wrong',
          'Discount Code not found',
          'error',
        );
      }
    } catch (error) {
      return Swal.fire('Something went wrong', error.toString(), 'error');
    } finally {
      dispatch(checkoutIsLoading(false));
    }
  };

export const checkoutIsLoading = (checkoutIsLoading) => ({
  type: V3_CHECKOUT_LOADING,
  payload: { checkoutIsLoading },
});

export const applyDiscountCodeToCart = (data) => ({
  type: V3_APPLY_DISCOUNT_CODE_TO_CART,
  payload: { data },
});

export const resetSubmittingOrder = () => {
  return {
    type: V3_RESET_SUBMITTING_ORDER,
  };
};

export const confirmShippingAddress = () => {
  return {
    type: V3_CONFIRM_SHIPPING_ADDRESS,
  };
};
