import { parsePhoneNumberFromString } from 'libphonenumber-js';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  addToCart,
  updateSMSNumberConfirmed,
} from '../../../../actions/v3Order';
import BackLinkFromProduct from '../../shared/BackLinkFromProduct.jsx';
import RequiredProductForm from '../required-product-form/RequiredProductForm.jsx';
import AddToCartButton from './subcomponents/AddToCartButton';
import BackgroundsPosesSelector from './subcomponents/BackgroundsPosesSelector';
import DeclineInterstitialProductuButton from './subcomponents/DeclineInterstitialProductuButton';
import DeclinePreCartOfferProductuButton from './subcomponents/DeclinePreCartOfferProductuButton';
import PersonalizationForm from './subcomponents/PersonalizationForm';
import ProductHeader from './subcomponents/ProductHeader';
import ProductSubheader from './subcomponents/ProductSubheader';
import SMSNumberConfirmation from './subcomponents/SMSNumberConfirmation';
import SelectedPosesAndBackgrounds from './subcomponents/SelectedPosesAndBackgrounds';
import cartUtils from '../../../../utils/cart';

const DoneWrapper = styled.div`
  font-size: 19px;
  font-weight: 800;
  text-align: left;

  @media (max-width: 991px) {
    margin-top: 24px;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  h6 {
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ProductOptionsWrapper = styled.div`
  margin: 20px 0;
`;

const RegularProductConfig = ({
  product,
  hideBackLink,
  incentiveProducts,
  interstitialProducts,
  productIsPreCartOffer,
  redirectSource,
}) => {
  console.log('🚀 ~ product:', product);
  const [backgroundSelections, setBackgroundSelections] = useState([]);
  const [backgroundsPosesSelectorVisible, setBackgroundsPosesSelectorVisible] =
    useState(false);
  const [personalizationValue, setPersonalizationValue] = useState('');
  const [isIncentiveProduct, setIsIncentiveProduct] = useState(false);
  const [isInterstitialProduct, setIsInterstitialProduct] = useState(false);
  const [isPreCartOfferProduct, setIsPreCartOfferProduct] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [personalizationOffered, setPersonalizationOffered] = useState(false);
  const [poseSelections, setPoseSelections] = useState([]);
  const [poseSelectionIds, setPoseSelectionIds] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [shouldOfferPersonalization, setShouldOfferPersonalization] =
    useState(true);
  const [selectedProductOptionKey, setSelectedProductOptionKey] =
    useState(null);
  const { control, handleSubmit, register } = useForm();

  const {
    allAvailablePosesForOrder,
    availablePoses,
    studentGallery,
    selectedStudent,
    isGeskus,
    studentGroupImages,
    studentId,
    studentPreviewImageUrl,
  } = useSelector((state) => state.additionalData);
  const orders = useSelector((state) => state.v3Order.orders);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const { phone, smsPhone } = useSelector((state) => state.parent);
  const { chargeForYpxBackgroundUpgrades } = useSelector(
    (state) => state.shoot,
  );
  const isPrepay = useSelector((state) => state.shoot.isPrepay);
  const isGreenScreen = useSelector((state) => state.shoot.isGreenScreen);
  const backgroundOptions = useSelector(
    (state) => state.shoot.backgroundOptions,
  );
  const prepayPersonalizationOfferType = useSelector(
    (state) => state.shoot.prepayPersonalizationOfferType,
  );
  const smsNumberConfirmed = useSelector(
    (state) => state.v3Order.smsNumberConfirmed,
  );

  const studentFirstName =
    selectedStudent?.firstName || selectedStudent?.first_name || null;
  const studentLastName =
    selectedStudent?.lastName || selectedStudent?.last_name || null;
  const hasStudent = studentFirstName && studentLastName;

  // console.log('==========================================');
  // console.log('Product for config:', product);
  // console.log('isPrepay:', isPrepay);
  // console.log('==========================================');

  const backgroundOptionsCount = product.background_options_count;
  const posesCount = product.poses_count;
  const isGroupImage = product.image_type === 'group_image';
  const studentGroupImage = (studentGroupImages && studentGroupImages[0]) || {};

  const orderContainsSingleBackgroundUpgrade =
    cartUtils.orderContainsSingleBackgroundUpgrade(
      orders,
      studentId,
      chargeForYpxBackgroundUpgrades,
    );

  // TODO: is there a better way of doing this?
  useEffect(() => {
    if (
      isPrepay &&
      prepayPersonalizationOfferType ===
        'prepay_personalization_prompted_before_checkout_only'
    ) {
      setShouldOfferPersonalization(false);
    }

    if (
      incentiveProducts &&
      incentiveProducts
        .map((incentiveProduct) => incentiveProduct.id)
        .includes(product.id)
    ) {
      setIsIncentiveProduct(true);
      Swal.fire(
        'Congratulations',
        'You qualify for a free bonus item. Please add your bonus item to your cart. You may need to select a background.',
      );
    }
  }, [product, prepayPersonalizationOfferType]);

  useEffect(() => {
    if (
      interstitialProducts &&
      interstitialProducts
        .map((interstitialProduct) => interstitialProduct.id)
        .includes(product.id)
    ) {
      setIsInterstitialProduct(true);
      Swal.fire(
        'Recommended Product',
        'Based on your order so far, here is a product we think you might like 😊',
      );
    }
  }, [product]);

  useEffect(() => {
    if (productIsPreCartOffer) {
      setIsPreCartOfferProduct(true);
      Swal.fire(
        'Is Your Cart Missing Something?',
        'Here is a product we think you might like!',
      );
    }
  }, [product]);

  /* on desktop, we want to show the backgrounds selector, which is otherwise hidden by default */
  // TODO: review how we're handling the conditional rendering logic
  useEffect(() => {
    if (
      windowWidth > 991 &&
      (backgroundOptionsCount > 0 || posesCount > 0) &&
      (backgroundOptions.length > 0 || availablePoses?.length > 0)
    ) {
      setBackgroundsPosesSelectorVisible(true);
    }
  }, [windowWidth]); // This effect runs whenever windowWidth changes

  const dispatch = useDispatch();

  const productBackgroundOptionsCount = product?.background_options_count || 0;
  const smsNumber = smsPhone;
  const productRequiresSMS = product.sms_required;
  const personalizationRequired =
    product.personalization_status === 'personalization_required';
  const personalizationOptional =
    product.personalization_status === 'personalization_optional';

  // toggles the visibility of the backgrounds and poses selector on mobile devices
  const togglePosesBackgroundsSelector = () => {
    console.log('togglePosesBackgroundsSelector...');
    setBackgroundsPosesSelectorVisible((prevState) => !prevState);
  };

  const resetConfig = () => {
    setBackgroundSelections([]);
    setPoseSelections([]);
    setPoseSelectionIds([]);
    setBackgroundsPosesSelectorVisible(true);
  };

  const handleConfirmSMS = async () => {
    const phoneNumberObject = parsePhoneNumberFromString(
      smsPhone || phone,
      'US',
    );

    if (!phoneNumberObject || !phoneNumberObject.isValid()) {
      Swal.fire('Invalid Phone Number', '', 'error');
      return;
    }
    const result = await Swal.fire({
      title: 'Please Confirm',
      text: `Please confirm ${phoneNumberObject.formatNational()} is the mobile number where we should send your SMS-based products`,
      confirmButtonText: 'OK',
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      cancelButtonText: 'Edit',
    });

    if (result.value === true) {
      dispatch(updateSMSNumberConfirmed(true));
      Swal.fire(
        'Confirmed',
        'You may now add the product to your cart',
        'success',
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      setEditMode(true);
    }
  };

  const addProductToCart = () => {
    handleSubmit((formState) => {
      if (!smsNumberConfirmed && productRequiresSMS) {
        handleConfirmSMS();
      } else {
        const backgroundIds = backgroundSelections.map(
          (background) => background.id,
        );

        if (productIsPreCartOffer) {
          mixpanel.track('Pre-Cart Offer Button Click', {
            Selection: 'Add to Cart',
          });
        }

        dispatch(
          addToCart({
            studentId: selectedStudent.id,
            fullproductData: product,
            backgroundSelections,
            backgroundIds,
            poseIds: poseSelectionIds,
            poses: poseSelections,
            isInterstitialProduct,
            productIsPreCartOffer: isPreCartOfferProduct,
            quantity: 1,
            personalizationValue,
            redirectSource,
            requiredFields: formState,
            productOptionKey: selectedProductOptionKey,
          }),
        );
      }
    })();
  };

  const addBackgroundSelection = (background) => {
    let updatedSelections = [...backgroundSelections];

    // If we're at max length, replace the last item. Otherwise, add to the list.
    if (updatedSelections.length === productBackgroundOptionsCount) {
      updatedSelections[updatedSelections.length - 1] = background;
    } else {
      updatedSelections.push(background);
    }

    setBackgroundSelections(updatedSelections);

    if (
      updatedSelections.length === productBackgroundOptionsCount ||
      windowWidth < 992
    ) {
      togglePosesBackgroundsSelector();
    }
  };

  const addPoseSelection = (id) => {
    let updatedSelectionIds = [...poseSelectionIds];
    let updatedSelections = [...poseSelections];

    const availablePosesToSelect = allAvailablePosesForOrder
      ? allAvailablePosesForOrder
      : availablePoses;

    if (updatedSelectionIds.length === posesCount) {
      updatedSelectionIds[updatedSelectionIds.length - 1] = id;
      updatedSelections[updatedSelections.length - 1] =
        availablePosesToSelect.find((pose) => pose.id === id);
    } else {
      updatedSelectionIds.push(id);
      updatedSelections.push(
        availablePosesToSelect.find((pose) => pose.id === id),
      );
    }

    setPoseSelectionIds(updatedSelectionIds);
    setPoseSelections(updatedSelections);

    // TODO: revist
    if (backgroundOptions?.length === 0 && windowWidth && windowWidth < 992) {
      // TODO: remove
      // console.log('togglePosesBackgroundsSelector here!');
      // console.log(windowWidth);
      togglePosesBackgroundsSelector();
    }
  };

  const canBeSubmitted = () => {
    // TODO: remove this
    // console.log('==========================================');
    // console.log('canBeSubmitted...');
    // console.log('backgroundOptions:', backgroundOptions);
    // console.log(
    //   'productBackgroundOptionsCount:',
    //   productBackgroundOptionsCount,
    // );
    // console.log('posesCount:', posesCount);
    // console.log('poseSelections:', poseSelections);
    // console.log('isPrepay:', isPrepay);
    // console.log('isGreenScreen:', isGreenScreen);
    // console.log('isGeskus:', isGeskus);
    // console.log('==========================================');

    // no gallery to select from, no backgrounds to select from
    if (
      (product?.product_options?.length || 0) > 0 &&
      !selectedProductOptionKey
    ) {
      console.log('canBeSubmitted: case 1');
      return false;
    }

    if (isPrepay && !isGreenScreen) {
      console.log('canBeSubmitted: case 2');
      return true;
    }

    if (isGeskus && !isPrepay && !isGreenScreen && posesCount > 0) {
      // TODO: revsit
      // special case for: 1) Geskus 2) post-event 3) non-green screen
      console.log('canBeSubmitted: case 3');
      return true;
    }

    // TODO: revsit
    // handle case for 1) Geskus 2) pose-event 3) green screen
    if (
      isGeskus &&
      !isPrepay &&
      isGreenScreen &&
      productBackgroundOptionsCount === backgroundSelections.length
    ) {
      console.log('canBeSubmitted: case 4');
      return true;
    }

    if (backgroundOptions.length === 0 && posesCount === 0) {
      console.log('canBeSubmitted: case 5');
      return true;
    }

    if (posesCount > 0 && poseSelections?.length === 0 && !isPrepay) {
      console.log('canBeSubmitted: case 6');
      return false;
    }

    if (
      !isGreenScreen &&
      !isPrepay &&
      !isGeskus &&
      posesCount > 0 &&
      posesCount === poseSelections.length
    ) {
      console.log('canBeSubmitted: case 7');
      return true;
    }

    console.log('productBackgroundOptionsCount', productBackgroundOptionsCount);
    console.log('backgroundSelections.length', backgroundSelections.length);

    return productBackgroundOptionsCount === backgroundSelections.length;
  };

  const onSkipPersonalization = () => {
    setPersonalizationValue(null);
    setPersonalizationOffered(true);
  };

  const onSubmitPersonalization = () => {
    setPersonalizationOffered(true);
  };

  const handleProductOptionChange = (e) => {
    setSelectedProductOptionKey(e.target.value);
  };

  const StudentGroupImageComponent = ({ isGroupImage, studentGroupImage }) => {
    if (!isGroupImage || !studentGroupImage?.url) {
      return null;
    }

    const studentName = hasStudent
      ? `${studentFirstName} ${studentLastName}`
      : `Your student`;

    return (
      <ImageContainer>
        <h6>{studentName}'s group photo:</h6>
        <ResponsiveImage src={studentGroupImage?.url} alt="Student Group" />
      </ImageContainer>
    );
  };

  // console.log(
  //   'BackgroundsPosesSelectorVisible:',
  //   backgroundsPosesSelectorVisible,
  // );

  // console.log('==========================================');
  // console.log('PERSONALIZATION SETTINGS');
  // console.log(`shouldOfferPersonalization: ${shouldOfferPersonalization}`);
  // console.log(`personalizationOffered: ${personalizationOffered}`);
  // console.log(`personalizationOptional: ${personalizationOptional}`);
  // console.log(`personalizationRequired: ${personalizationRequired}`);
  // console.log('==========================================');

  // TODO: clean up the conditional rendering logic
  return (
    <div id="v3-regular-product-config">
      <div className="bnl-product-config">
        <BackLinkFromProduct
          hideBackLink={hideBackLink}
          hidden={isIncentiveProduct || isInterstitialProduct}
        />
        <ProductHeader product={product} />
        <ProductSubheader product={product} />
        <div className="row">
          <div className="bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4">
            <div
              className={`bnl-product-config__product ${
                backgroundsPosesSelectorVisible
                  ? 'element-hidden'
                  : 'element-visible'
              }`}
            >
              {/* <div className="product-config__header">
                <h5 className="bnl-product-config__desc">{product.name}</h5>
              </div> */}
              <img
                className="bnl-product-config__product-image"
                src={product.image_thumbnail}
              />
              <div className="bnl-product-config__product-desc">
                {product.description}
              </div>
              <StudentGroupImageComponent
                isGroupImage={isGroupImage}
                studentGroupImage={studentGroupImage}
              />
              {backgroundOptions.length > 0 || posesCount ? (
                <SelectedPosesAndBackgrounds
                  backgroundOptions={backgroundOptions}
                  backgroundOptionsCount={backgroundOptionsCount}
                  backgroundSelections={backgroundSelections}
                  isGreenScreen={isGreenScreen}
                  posesCount={posesCount}
                  poseSelections={poseSelections}
                  productBackgroundOptionsCount={productBackgroundOptionsCount}
                  resetConfig={resetConfig}
                  togglePosesBackgroundsSelector={
                    togglePosesBackgroundsSelector
                  }
                  studentPreviewImageUrl={studentPreviewImageUrl}
                  isPrepay={isPrepay}
                />
              ) : null}
              {/* {this.props.renderDesktopSelectedPoseAndBackground()} */}
              <div className="hidden-xs hidden-sm">
                <AddToCartButton
                  addProductToCart={addProductToCart}
                  canBeSubmitted={canBeSubmitted}
                  product={product}
                />
                {isInterstitialProduct && (
                  <DeclineInterstitialProductuButton product={product} />
                )}
                {isPreCartOfferProduct && (
                  <DeclinePreCartOfferProductuButton
                    product={product}
                    redirectSource={redirectSource}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="bnl-product-config__step col-xs-12 col-md-8 col-lg-8">
            {backgroundsPosesSelectorVisible && (
              <BackgroundsPosesSelector
                addBackgroundSelection={addBackgroundSelection}
                addPoseSelection={addPoseSelection}
                allAvailablePosesForOrder={allAvailablePosesForOrder}
                availablePoses={availablePoses}
                backgroundSelections={backgroundSelections}
                backgroundOptions={backgroundOptions}
                backgroundsPosesSelectorVisible={
                  backgroundsPosesSelectorVisible
                }
                canBeSubmitted={canBeSubmitted()}
                isPrepay={isPrepay}
                orderContainsSingleBackgroundUpgrade={
                  orderContainsSingleBackgroundUpgrade
                }
                posesCount={posesCount}
                poseSelections={poseSelections}
                poseSelectionIds={poseSelectionIds}
                productBackgroundOptionsCount={productBackgroundOptionsCount}
                studentGallery={studentGallery}
              />
            )}
            {canBeSubmitted() &&
              shouldOfferPersonalization &&
              !personalizationOffered &&
              (personalizationOptional || personalizationRequired) && (
                <PersonalizationForm
                  onSkipPersonalization={onSkipPersonalization}
                  onSubmitPersonalization={onSubmitPersonalization}
                  personalizationValue={personalizationValue}
                  setPersonalizationValue={setPersonalizationValue}
                />
              )}
            {canBeSubmitted() &&
              (!(personalizationOptional || personalizationRequired) ||
                ((personalizationOptional || personalizationRequired) &&
                  personalizationOffered) ||
                !shouldOfferPersonalization) &&
              productRequiresSMS && (
                <SMSNumberConfirmation
                  currentUserId={currentUserId}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  smsNumber={smsNumber}
                  phone={phone}
                  smsNumberConfirmed={smsNumberConfirmed}
                />
              )}
            {canBeSubmitted() &&
              (!(personalizationOptional || personalizationRequired) ||
                ((personalizationOptional || personalizationRequired) &&
                  personalizationOffered) ||
                !shouldOfferPersonalization) &&
              !backgroundsPosesSelectorVisible &&
              !productRequiresSMS && (
                <DoneWrapper>
                  Configuration complete. Please click 'Add to Cart'
                </DoneWrapper>
              )}
            <RequiredProductForm
              product={product}
              register={register}
              control={control}
            />
            {Array.isArray(product.product_options) &&
              product.product_options.length > 0 && (
                <ProductOptionsWrapper>
                  <label htmlFor="product-option">
                    Product Option{'  '}
                    <select
                      name="product-option"
                      onChange={handleProductOptionChange}
                      value={selectedProductOptionKey ?? ''}
                    >
                      <option value="">Select...</option>
                      {product.product_options.map((option) => (
                        <option key={option.id} value={option.key}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </ProductOptionsWrapper>
              )}
            <div className="hidden-md hidden-lg hidden-xl">
              <AddToCartButton
                addProductToCart={addProductToCart}
                canBeSubmitted={canBeSubmitted}
                product={product}
              />
              {isInterstitialProduct && (
                <DeclineInterstitialProductuButton product={product} />
              )}
              {isPreCartOfferProduct && (
                <DeclinePreCartOfferProductuButton product={product} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularProductConfig;
