import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { setBnlPreShootStudentsDetails } from '../../../actions/action_bnl_pre_shoot';
import {
  getShootData,
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
  setRetouchingProduct,
  setRetouchingProducts,
  setPersonalizationProduct,
  setInterstitialProducts,
  setPreCartOfferProducts,
  setTriggersPreCartOfferProducts,
} from '../../../actions/action_shoot';
import { setAdditionalData } from '../../../actions/additionalData';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import { updateBnlUnlockedGroups } from '../../../actions/action_cart';

// React.js components
import IncentiveDisplay from '../../incentives/incentive_display';
import Dropdown from '../../utils/dropdown';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';

import UIContext from '../../../contexts/UIContext';

const PreshootDetailsWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 100px 0;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

class BnlParentStudentPreShootDetailForm extends Component {
  static contextType = UIContext;

  constructor(props) {
    super(props);

    this.STEPS = {
      form: 0,
      incentive: 1,
    };

    this.state = {
      activeComponent: this.STEPS.form,
      age: '',
      coachName: '',
      grade: '',
      teacherSalutation: '',
      teacherLastName: '',
      teamName: '',
      homeRoom: '',
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.goToOrder = this.goToOrder.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.submitForm(event);
    }
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  deepCloneObject(object) {
    // converts date objects to strings but not back to dates
    return JSON.parse(JSON.stringify(object));
  }

  cloneArray(array) {
    return array.slice();
  }

  goToOrder() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
    this.props.history.push('/order');
  }

  formatBackgroundOptions(containsBackgroundCategories, backgroundOptions) {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    this.props.formatAndSetBackgroundOptions(formattedBackgroundOptions);
  }

  formatIncentiveProducts(incentiveProducts) {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      if (!formattedIncentiveProducts[incentiveProduct.incentive_threshold]) {
        formattedIncentiveProducts[incentiveProduct.incentive_threshold] = [];
      }

      formattedIncentiveProducts[incentiveProduct.incentive_threshold].push(
        incentiveProduct,
      );
    });

    this.props.formatAndSetIncentiveProducts(formattedIncentiveProducts);
  }

  setUnlockedGroups(studentId) {
    const orderState = this.props.cart.orders[studentId];
    let bnlUnlockedGroups = [];

    if (orderState) {
      bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);

      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.lock_type === 'unlocker') {
          cartItem.lock_group.forEach((set) => {
            if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
          });
        }
      });
    }

    this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
  }

  submitForm(event) {
    event.preventDefault();

    const { priceListEventType } = this.props.additionalData;
    const uiSettings = this.context; // get UI version from context
    const { uiVersion } = uiSettings;

    if (priceListEventType != 'sports' && !this.state.grade) {
      Swal.fire('Please select a grade');
    } else if (priceListEventType == 'sports' && !this.state.coachName) {
      Swal.fire("Please enter the Coach's name");
    } else {
      const that = this;
      const {
        additionalData: { keySubmittedByUser },
        bnlPreShoot,
        setPersonalizationProduct,
      } = this.props;
      const student = bnlPreShoot.selectedStudent;
      const studentId = bnlPreShoot.selectedStudent.id;
      const studentName = `${bnlPreShoot.selectedStudent.firstName} ${bnlPreShoot.selectedStudent.lastName}`;
      const { shootKey } = bnlPreShoot;

      const details = {
        grade: this.state.grade,
        homeRoom: this.state.homeRoom,
        teacherSalutation: this.state.teacherSalutation,
        teacherLastName: this.state.teacherLastName,
        coachName: this.state.coachName,
        teamName: this.state.teamName,
        age: this.state.age,
      };

      const data = {
        student_id: bnlPreShoot.selectedStudent.id,
        shoot_key: bnlPreShoot.shootKey,
        grade_code: this.state.grade,
        key_submitted_by_user: keySubmittedByUser,
        coach_name: this.state.coachName,
        team_name: this.state.teamName,
        age: this.state.age,
      };

      const { grade } = this.state;
      const salutation = this.state.teacherSalutation || 'None';
      const lastName = this.state.teacherLastName || 'None';
      const homeRoom = this.state.homeRoom || 'None';
      const coachName = this.state.coachName || 'None';
      const teamName = this.state.teamName || 'None';
      const age = this.state.age || 'None';

      let alertTitle =
        'Please verify your student info is exactly how it would appear in school records';
      let alertHtml = `<b>Student Name</b>: ${studentName}<br> <b>School Key</b>: ${shootKey}<br> <b>Grade</b>: ${grade}<br> <b>Teacher Salutation</b>: ${salutation}<br> <b>Teacher Last Name</b>: ${lastName}<br> <b>Home Room</b>: ${homeRoom}`;

      if (priceListEventType === 'sports') {
        alertTitle = 'Please verify your athlete info is correct';
        alertHtml = `<b>Athlete Name</b>: ${studentName}<br> <b>Event Key</b>: ${shootKey}<br> <b>Coach Name</b>: ${coachName}<br> <b>Team Name</b>: ${teamName}<br> <b>Age</b>: ${age}`;
      }

      Swal.fire({
        title: alertTitle,
        html: alertHtml,
        showCancelButton: true,
        reverseButtons: true,
        focusConfirm: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'OK',
      }).then((res) => {
        if (res.value) {
          that.setState({ loading: true }, () => {
            that.props.setBnlPreShootStudentsDetails(studentId, details);
            that.props.getShootData(data).then((resp) => {
              that.setState({ loading: false }, () => {
                if (!resp.payload.data.errors) {
                  let isGeskus = false;

                  // get the ui version from the event (shoot)
                  const eventUIVersion = resp.payload.data.shoot?.ui_version;

                  that.formatBackgroundOptions(
                    resp.payload.data.contains_background_categories,
                    resp.payload.data.background_options,
                  );
                  that.props.setRetouchingProduct(
                    resp.payload.data.retouching_product,
                  );
                  that.props.setRetouchingProducts(
                    resp.payload.data.retouching_products,
                  );

                  setPersonalizationProduct(
                    resp.payload.data.personalization_product,
                  );

                  that.props.setInterstitialProducts(
                    resp.payload.data.interstitial_products,
                  );

                  that.props.setPreCartOfferProducts(
                    resp.payload.data.pre_cart_offer_products,
                  );

                  that.props.setTriggersPreCartOfferProducts(
                    resp.payload.data.triggers_pre_cart_offer_products,
                  );

                  that.formatIncentiveProducts(
                    resp.payload.data.incentive_products,
                  );

                  if (
                    resp.payload.data.shoot.company_auth_token.toLowerCase() ===
                    'geskus'
                  ) {
                    isGeskus = true;
                  }

                  that.props.setAdditionalData({
                    eventUIVersion,
                    isGeskus,
                    selectedStudent: student,
                    shootKey: bnlPreShoot.shootKey,
                    student,
                    studentId: student.id,
                    uiVersion,
                  });

                  that.setUnlockedGroups(studentId);

                  // TODO: v3 - remove
                  console.log('%corder entry point: 1', 'color: yellow;');
                  console.log(
                    `%cdefault UI version: ${uiVersion}`,
                    `color: yellow;`,
                  );
                  console.log(
                    `%cevent UI version: ${eventUIVersion}`,
                    `color: yellow;`,
                  );

                  // TODO: v3 - move to helper function
                  if (
                    ['3', 'v3'].includes(uiVersion) ||
                    eventUIVersion === 'ui_v3'
                  ) {
                    this.props.history.push('/v3/incentive-offers');
                  } else {
                    that.setState({ activeComponent: this.STEPS.incentive });
                  }
                }
              });
            });
          });
        }
      });
    }
  }

  renderGradeDropDown() {
    const options = [
      'SELECT GRADE',
      'Pre-K Full',
      'Pre-K AM',
      'Pre-K PM',
      'K-Full',
      'K-AM',
      'K-PM',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
    ];

    return (
      <div>
        <label>Grade</label>
        <Dropdown
          className="order-shoot-key-form__dropdown"
          options={options}
          value={this.state.gradeCode}
          name="grade"
          handleOnChange={this.handleOnChange}
        />
      </div>
    );
  }

  renderTeacherSalutationDropDown() {
    const options = ['SELECT SALUTATION', 'Mr.', 'Mrs.', 'Ms.', 'Dr.'];

    return (
      <div>
        <label>Teacher Salutation (optional)</label>
        <Dropdown
          className="order-shoot-key-form__dropdown"
          options={options}
          value={this.state.gradeCode}
          name="teacherSalutation"
          handleOnChange={this.handleOnChange}
        />
      </div>
    );
  }

  renderActiveComponent() {
    switch (this.state.activeComponent) {
      case this.STEPS.form:
        return this.renderActualComponent();
      case this.STEPS.incentive:
        return <IncentiveDisplay goToOrder={this.goToOrder} />;
    }
  }

  renderActualComponent() {
    const additionalData = this.props.additionalData;
    const student = this.props.bnlPreShoot.selectedStudent;
    const { priceListEventType } = additionalData;

    let subjectName = 'student';

    if (priceListEventType === 'sports') {
      subjectName = 'athlete';
    }

    return (
      <PreshootDetailsWrapper>
        <h3 className="bnl-dashboard__pre-shoot-form__header">
          Please confirm your {subjectName}'s details
        </h3>
        <div className="bnl-dashboard__pre-shoot-form__subheader">
          We use this information to match your order with your {subjectName}{' '}
          after picture day
        </div>
        <div className="bnl-dashboard__pre-shoot-form__student-name">
          {student.firstName} {student.lastName}
        </div>
        <div className="bnl-dashboard__pre-shoot-form__shoot-key">
          {this.props.bnlPreShoot.shootKey}
        </div>
        <div className="bnl-dashboard__pre-shoot-form__shoot-name">
          {this.props.bnlPreShoot.shootName}
        </div>
        <form
          className="bnl-dashboard__pre-shoot-form__form"
          onSubmit={this.submitForm}
        >
          {priceListEventType === 'sports' && (
            <>
              <label>Coach Name (required)</label>
              <input
                className="bnl-dashboard__pre-shoot-form__form__input"
                name="coachName"
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                placeholder=""
                value={this.state.coachName}
              />
              <label>Team Name (optional)</label>
              <input
                className="bnl-dashboard__pre-shoot-form__form__input"
                name="teamName"
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                placeholder=""
                value={this.state.teamName}
              />
              <label>Age (optional)</label>
              <input
                className="bnl-dashboard__pre-shoot-form__form__input"
                name="age"
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                placeholder=""
                value={this.state.age}
              />
            </>
          )}
          {priceListEventType !== 'sports' && this.renderGradeDropDown()}
          {priceListEventType !== 'sports' &&
            this.renderTeacherSalutationDropDown()}
          {priceListEventType !== 'sports' && (
            <>
              <label>Teacher Last Name (optional)</label>
              <input
                className="bnl-dashboard__pre-shoot-form__form__input"
                name="teacherLastName"
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                placeholder=""
                value={this.state.teacherLastName}
              />
              <label>Home Room (optional)</label>
              <input
                className="bnl-dashboard__pre-shoot-form__form__input"
                name="homeRoom"
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                placeholder=""
                value={this.state.homeRoom}
              />
            </>
          )}
          <input
            className="bnl-dashboard__pre-shoot-form__button button--round-border bg--bnl-light-blue"
            type="submit"
            value="Next"
          />
        </form>
      </PreshootDetailsWrapper>
    );
  }

  render() {
    const loading = this.state.loading ? 'loading' : 'hidden';

    return (
      <div>
        <div className={loading}>
          <div className="spinner" />
        </div>
        {this.renderActiveComponent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      formatAndSetBackgroundOptions,
      formatAndSetIncentiveProducts,
      getShootData,
      setAdditionalData,
      setBnlPreShootStudentsDetails,
      setRetouchingProduct,
      setRetouchingProducts,
      setPersonalizationProduct,
      setInterstitialProducts,
      setPreCartOfferProducts,
      setTriggersPreCartOfferProducts,
      updateBnlUnlockedGroups,
      updateOrderActiveComponent,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BnlParentStudentPreShootDetailForm);

// TODO: add props validation
